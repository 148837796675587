// ADVANCED FILTERS TO TABLEBANKACCOUNTS
export default [
  {
    // [0]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    // [1]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    // [2]
    type: "input-percent",
    margin: true,
    showLabel: true,
    label: "Min. % From: ",
    model: null,
    placeholder: "Initial Range",
    cols: 6
  },
  {
    // [3]
    type: "input-percent",
    margin: true,
    showLabel: true,
    model: null,
    label: "Min. % To:",
    placeholder: "Final Range",
    cols: 6,
  },
  {
    // [4]
    type: "switch",
    margin: true,
    showLabel: true,
    label: "Settle with A.M.G",
    model: null,
    options: [
      { value: 1, label: "YES" },
      { value: 2, label: "NO" }
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
  },
];
