<template>
  <div>
    <b-modal v-model="showModal" @hidden="hideModal" no-close-on-backdrop>
      <template #modal-title>
        <div class="d-flex justify-content-left align-items-center">
          <img
            src="@/assets/images/icons/keywords.svg"
            width="25px"
            height="25px"
            class="custom-icon mr-50"
          />
          <h5 class="text-white font-weight-bolder text-uppercase p-sm m-0">
            Create or edit keywords of creditor "{{ creditor.name }}"
          </h5>
        </div>
      </template>
      <b-container no-body class="py-1">
        <b-form-group label="keywords">
          <template #label>
            Keywords
            <small class="text-warning ml-50">(separated by commas)</small>
          </template>
          <b-form-input
            id="keywords"
            v-model="newKeywords"
            placeholder="Add keywords separated by commas"
          >
          </b-form-input>
        </b-form-group>
        <div
          class="rounded-lg p-1 w-100 h-auto mt-2"
          :class="isDarkSkin ? 'bg-dark' : 'custom-secondary'"
        >
          <template
            v-if="keywordArray.length > 0 || newKeywordArray.length > 0"
          >
            <b-badge
              v-for="(keyword, index) in keywordArray"
              :key="index"
              variant="light-success"
              class="m-50 px-1 py-50 border-success"
              style="font-size: 13px"
            >
              {{ keyword }}
              <feather-icon
                icon="XIcon"
                size="18"
                class="ml-50 cursor-pointer text-danger hover-me"
                @click="deleteKeyword(index)"
              />
            </b-badge>
            <b-badge
              variant="light-warning"
              v-for="(keyword, index) in newKeywordArray"
              :key="`${index}_new`"
              class="m-50 px-1 py-50 border-warning"
              style="font-size: 13px"
            >
              {{ keyword }}
            </b-badge>
          </template>
          <template v-else>
            <div class="d-flex justify-content-around align-items-center p-4">
              <img
                src="@/assets/images/icons/keywords.svg"
                width="25%"
                height="25%"
                style="max-width: 80px; max-height: 80px"
                class="mr-50"
                :class="isDarkSkin ? 'custom-icon' : 'custom-icon-light'"
              />
              <h4 class="text-secondary">The keywords appear here</h4>
            </div>
          </template>
        </div>
      </b-container>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button
            variant="success"
            @click="createKeyword()"
            :disabled="newKeywordArray.length == 0"
          >
            SAVE
            <feather-icon icon="SaveIcon" />
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";

export default {
  props: {
    creditor: {
      type: Object,
      required: true,
    },
    creditorSelected: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: true,
      newKeywords: null,
      keywordArray: [],
      newKeywordArray: [],
    };
  },
  watch: {
    newKeywords(value) {
      this.newKeywordArray = [];
      // clean: values empty, duplicate
      this.newKeywordArray = value.length
        ? value
            .split(",")
            .filter((item) => item.trim())
            .map((item) => item.trim())
            .filter((item, index, array) => array.indexOf(item) === index)
        : [];
    },
  },
  async created() {
    await this.getKeywords();
  },
  methods: {
    async getKeywords() {
      const { data } = await CreditorsService.getKeywords({
        id: this.creditor.id,
      });
      this.keywordArray = JSON.parse(data[0].keywords) ?? [];
    },
    async deleteKeyword(index) {
      let currentKeywords = [...this.keywordArray];
      currentKeywords.splice(index, 1);
      const params = {
        id: this.creditor.id,
        keywords: currentKeywords,
      };
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const { data } = await CreditorsService.deleteKeyword(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successful operation"
          );
          this.creditorSelected.keywords_qty = currentKeywords.length;
          this.getKeywords();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async createKeyword() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      let currentKeywords = [...this.keywordArray];
      let newKeywordsJson =
        this.keywordArray == null
          ? this.newKeywordArray
          : [...currentKeywords, ...this.newKeywordArray];
      newKeywordsJson = newKeywordsJson.filter((item) => item != " ");
      const params = {
        id: this.creditor.id,
        keywords: newKeywordsJson,
      };
      try {
        this.addPreloader();
        const { data } = await CreditorsService.saveKeyword(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successful operation"
          );
          this.creditorSelected.keywords_qty = newKeywordsJson.length;
          this.$emit("hideModal");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped>
.p-sm {
  padding: 5px 8px;
}
.custom-icon {
  filter: invert(1);
}
.custom-icon-light {
  filter: invert(0.7);
}
.custom-secondary {
  background-color: #f5f5f5 !important;
}
</style>