<template>
  <div>
    <b-modal
      v-model="modalCenter"
      centered
      size="lg"
      title="CLIENTS LIST"
      title-tag="h3"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="hideModal"
      hide-footer
    >
      <b-container>
        <b-table
          slot="table"
          ref="refClientsList"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: CLIENTS -->
          <template #cell(name_lead)="data">
            <div class="text-left">
              <router-link
                class
                :to="`/debtsolution/clients/account/${data.item.id_account}`"
              >
                {{ data.item.name_lead }}</router-link
              >
            </div>
          </template>
          <!-- Column: cant_Creditor -->
          <template #cell(cant_Creditor)="data">
            <div class="d-flex justify-content-center">
              <p
                class="bg-secondary widthClients text-center text-wrap"
                :class="isDarkSkin ? 'text-white ' : 'text-dark '"
              >
                {{ data.item.cant_Creditor }}
              </p>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import CreditorService from "../../service/creditors.service";
export default {
  components: {},
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "name_lead",
          label: "Clients",
          tdClass: "pt-1",
        },
        {
          label: "",
          key: "cant_Creditor",
          thClass: "text-center",
        },
        {
          label: "Creditor Name",
          key: "value",
          thClass: "text-left",
          tdClass: "pt-1",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await CreditorService.getListClient({
          idcollection: this.userData.id,
        });
        return result;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.widthClients {
  padding-top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin: 0;
  font-weight: bold;
}
</style>
