<template>
  <div>
    <template v-for="(item, index) in list">
      <div class="text-center" v-if="setLimit(index)" :key="index">
        <p class="m-0">{{ item[itemLabel] }}</p>
      </div>
    </template>
    <div class="text-center d-flex flex-column" v-if="showButton && list.length > limit">
      <span style="margin-bottom: 3px" v-if="!showAll">...</span>
      <b-badge
        @click="toggleShowAll"
        v-if="!showAll"
        size="sm"
        variant="light-info"
        >Show all</b-badge
      >
      <b-badge
        @click="toggleShowAll"
        size="sm"
        variant="light-info"
        style="margin-top: 5px"
        v-else
        >Hide</b-badge
      >
      <!-- <b-button>Hide</b-button> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    itemLabel: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showAll: false
    }
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll
    },
    setLimit(index) {
      if (this.showAll) {
        return true
      } else {
        return index < this.limit
      }
    },
  },
}
</script>
<style lang="scss"></style>
