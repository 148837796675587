<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refUserListTable'].refresh()"
      >
        <b-table
          slot="table"
          ref="refUserListTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          sticky-header="68vh"
          small
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="fields"
          :items="myProvider"
          :busy.sync="isBusy"
          :sort-desc.sync="sortBy"
          :sort-by.sync="orderby"
          thead-class="hidden_header"
          hover
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #top-row>
            <b-th colspan="5"></b-th>
            <!-- <b-th class="py-0">
              <b-row>
                <b-col
                  cols="3"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                >
                  Name
                </b-col>
                <b-col
                  cols="3"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                >
                  Phone
                </b-col>
                <b-col
                  cols="3"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                >
                  Email
                </b-col>
                <b-col
                  cols="3"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                >
                  Fax
                </b-col>
              </b-row>
            </b-th> -->
            <b-th class="py-0">
              <b-row>
                <!-- <b-col
                  cols="3"
                  class="widthRecovery text-center p-0 bg-gradient-secondary"
                >
                  Name
                </b-col> -->
                <b-col
                  cols="4"
                  class="widthRecovery text-center p-0 bg-gradient-secondary"
                >
                  Phone
                </b-col>
                <b-col
                  cols="4"
                  class="widthRecovery text-center p-0 bg-gradient-secondary"
                >
                  Email
                </b-col>
                <b-col
                  cols="4"
                  class="widthRecovery text-center p-0 bg-gradient-secondary"
                >
                  Fax
                </b-col>
              </b-row>
            </b-th>
            <b-th colspan="2"></b-th>
          </template>
          <template #cell(value)="data">
            <span>{{ data.item.value }}</span>
            <p v-if="data.item.settlement">{{ data.item.settlement }} %</p>
            <!-- <div class="d-flex justify-content-start">
              <span
                class="text-center widthClients text-light"
                :class="
                  data.item.c_assigned != 0
                    ? 'bg-secondary cursor-pointer'
                    : 'bg-red'
                "
                @click="
                  data.item.c_assigned != 0
                    ? openModalClientsList(data.item)
                    : null
                "
              >
                {{ data.item.c_assigned }}
              </span>
            </div> -->
          </template>
          <template #cell(c_assigned)="data">
            <div class="d-flex justify-content-center">
              <span
                class="text-center widthClients text-light"
                :class="
                  data.item.c_assigned != 0
                    ? 'bg-secondary cursor-pointer'
                    : 'bg-red'
                "
                @click="
                  data.item.c_assigned != 0
                    ? openModalClientsList(data.item)
                    : null
                "
              >
                {{ data.item.c_assigned }}
              </span>
            </div>
          </template>

          <!-- Column: % MIN -->
          <template #cell(settlement)="data">
            <p v-if="data.item.settlement">{{ data.item.settlement }} %</p>
          </template>
          <!-- Column: settle-->
          <template #cell(settle)="data">
            <div v-if="data.item.settle" class="text-center">
              <b-badge
                :variant="data.item.settle == 'Yes' ? 'success' : 'danger'"
                >{{ data.item.settle }}</b-badge
              >
            </div>
          </template>
          <!-- Column: recovery-->
          <template #cell(recovery)="data">
            <b-row>
              <b-col class="text-center">
                {{ data.item.name_recovery }}
              </b-col>
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.recovery || '[]')"
                  :itemLabel="'phone'"
                  :showButton="data.item.recovery ? true : false"
                  :limit="2"
                />
              </b-col>
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.email || '[]')"
                  :itemLabel="'email'"
                  :showButton="data.item.email ? true : false"
                  :limit="2"
                />
              </b-col>
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.fax || '[]')"
                  :itemLabel="'fax'"
                  :showButton="data.item.fax ? true : false"
                  :limit="2"
                />
              </b-col>
            </b-row>
          </template>
          <!-- Column: Customer -->
          <template #cell(customer)="data">
            <b-row>
              <!-- <b-col class="text-center">
                {{ data.item.customer_name }}
              </b-col> -->
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.customer_phone || '[]')"
                  :itemLabel="'phone'"
                  :showButton="data.item.customer_phone ? true : false"
                  :limit="2"
                />
              </b-col>
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.customer_emails || '[]')"
                  :itemLabel="'email'"
                  :showButton="data.item.customer_emails ? true : false"
                  :limit="2"
                />
              </b-col>
              <b-col>
                <show-more-array
                  :list="JSON.parse(data.item.customer_faxs || '[]')"
                  :itemLabel="'fax'"
                  :showButton="data.item.customer_faxs ? true : false"
                  :limit="2"
                />
              </b-col>
            </b-row>
          </template>
          <!-- Column: Info -->
          <template #cell(user_creator)="data">
            <div class="text-center">
              <b-button :id="`target-${data.item.id}`" variant="outline">
                <feather-icon
                  icon="HelpCircleIcon"
                  size="15"
                  class="text-primary"
                />
              </b-button>
              <b-tooltip :target="`target-${data.item.id}`" placement="left">
                <p class="text-left m-0">{{ data.item.user_creator }}</p>
                <p>{{ data.item.created_at }}</p>
                <p class="text-left m-0">{{ data.item.user_updated }}</p>
                <p>{{ data.item.updated_at }}</p>
              </b-tooltip>
            </div>
          </template>
          <!-- Column: Keywords -->
          <template #cell(keywords)="{ item }">
            <b-button
              size="sm"
              variant="primary"
              class="position-relative"
              @click="showKeywordModal(item)"
            >
              <img
                src="@/assets/images/icons/keywords.svg"
                width="20px"
                height="20px"
                class="custom-icon"
              />
              <span class="badge badge-up badge-pill badge-important">
                {{ showKeywordQty(item) }}
              </span>
            </b-button>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <div class="mt-1">
              <!-- Edit -->
              <b-button
                variant="outline"
                class="pt-0"
                @click="openModalEditCreditor(data.item)"
              >
                <feather-icon icon="EditIcon" class="text-info" />
              </b-button>
              <!-- Delete -->
              <b-button
                v-if="currentUser.role_id <= 2"
                variant="outline"
                class="pl-0 pt-0"
                @click="deleteCreditor(data.item)"
              >
                <feather-icon icon="TrashIcon" class="text-danger" />
              </b-button>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <ModalEditCreditor
      v-if="modalEditCreditorState"
      :userData="dataModal"
      @updateTable="myProvider"
      @hideModal="modalEditCreditorState = false"
    />
    <ModalClientsList
      v-if="modalClientsListState"
      :userData="dataClientsList"
      @hideModal="modalClientsListState = false"
    />
    <CreditorKeywords
      v-if="showModal"
      :creditor="paramsKeywords"
      :creditorSelected="creditorSelected"
      @hideModal="showModal = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DataFields from "./fields.data";
// import FilterSlot from "./FilterSlot.vue";
import CreditorsService from "../../service/creditors.service";
import ModalEditCreditor from "../modal/ModalEditCreditor.vue";
import ModalClientsList from "../modal/ModalClientsList.vue";
import ShowMoreArray from "@/views/components/show-more-array/ShowMoreArray.vue";
import FiltersData from "@/views/debt-solution/views/creditors/components/table/filters.data.js";
import CreditorKeywords from "@/views/debt-solution/views/creditors/components/modal/CreditorKeywords.vue";

export default {
  components: {
    // FilterSlot,
    ModalEditCreditor,
    ModalClientsList,
    ShowMoreArray,
    CreditorKeywords,
  },
  data() {
    return {
      filter: FiltersData,
      fields: DataFields.CreditorsFields,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by creditor name or creditor parent",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      items: [],
      modalEditCreditorState: false,
      modalClientsListState: false,
      dataClient: [],
      dataModal: {},
      dataModalEdit: [],
      dataClientsList: {},
      all: { id: 0, label: "All" },
      advisor: [],
      sortBy: false,
      orderby: "id",
      orderColName: true,
      showAll: false,
      paramsKeywords: {},
      showModal: false,
      creditorSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_TABLE_CREDITORS:
        "DebtSolutionCreditors/G_UPDATE_TABLE_CREDITORS",
    }),
    routeModule() {
      return this.$route.meta.route;
    },
  },
  methods: {
    ...mapActions({
      A_GET_STATUS_EEUU: "DebtSolutionCreditors/A_GET_STATUS_EEUU",
      A_GET_COUNTRIES: "DebtSolutionCreditors/A_GET_COUNTRIES",
    }),
    openModalClientsList(data) {
      this.dataClientsList = data;
      this.modalClientsListState = true;
    },
    async openModalEditCreditor(data) {
      try {
        let result = await CreditorsService.EDIT_CREDITORS({ id: data.id });
        this.dataModal = {
          name_text: this.filterPrincipal.model,
          id: result[0].id,
          parentName: result[0].parent_name,
          creditorName: result[0].value,
          rName: result[0].name_recovery,
          recoveryFax:
            result[0].fax == null ? [{ fax: "" }] : JSON.parse(result[0].fax),
          recoveryEmail:
            result[0].email == null
              ? [{ email: "" }]
              : JSON.parse(result[0].email),
          customerPhone:
            result[0].phone == null
              ? [{ phone: "" }]
              : JSON.parse(result[0].phone),
          settlement: result[0].settlement,
          settle: result[0].settle,
          website: result[0].website,
          creditorMailingAddress: result[0].mailing_address,
          creditorAddress: result[0].address,
          creditorCity: result[0].city,
          creditorState: result[0].id_state,
          creditorZipCode: result[0].zip_code,
          creditorCountry: result[0].id_country,
          parent_id: result[0].parent_id,
          agent: result[0].agent,
          offer_contact_number: result[0].offer_contact_number,
          avg_percent_creditor: result[0].avg_percent,
          customer_name: result[0].customer_name,
          customerFax:
            result[0].customer_faxs == null
              ? [{ fax: "" }]
              : JSON.parse(result[0].customer_faxs),

          customerEmail:
            result[0].customer_emails == null
              ? [{ email: "" }]
              : JSON.parse(result[0].customer_emails),
          timezone_id: result[0].timezone_id,
          payment_type: result[0].payment_type,
          agency: result[0].agency,
          creditor_website: result[0].creditor_website,
          average_trade_percentage: result[0].average_trade_percentage,
          interest_rate: result[0].interest_rate,
          send_types:
            result[0].send_types == null
              ? []
              : JSON.parse(result[0].send_types),
          debt_types:
            result[0].debt_types == null
              ? []
              : JSON.parse(result[0].debt_types),
          schedule:
            result[0].schedule == null ? [] : JSON.parse(result[0].schedule),

          city_address: result[0].city_address,
          state_address: result[0].id_state_address,
          zip_code_address: result[0].zip_code_address,
          country_address: result[0].id_country_address,

          recovery_phone:
            result[0].recovery_phone == null
              ? [{ id: 0, phone: "" }]
              : JSON.parse(result[0].recovery_phone),
          recovery_agent:
            result[0].recovery_agent == null
              ? [{ id: 0, agent: "", extension: "" }]
              : JSON.parse(result[0].recovery_agent),
          recovery_phone_delete: [],
          recovery_agent_delete: [],
        };
        this.modalEditCreditorState = true;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    async myProvider(ctx) {
      try {
        this.isBusy = true;

        let order = ctx.sortDesc ? "asc" : "desc";
        let orderby = ctx.sortBy ? ctx.sortBy : "id";
        order = ctx.sortBy ? order : "desc";

        let result = await CreditorsService.GET_CREDITORS({
          from: this.filter[0].model,
          to: this.filter[1].model,
          from_percent: this.filter[2].model,
          to_percent: this.filter[3].model,
          settle_with_amg: this.filter[4].model,
          name_text: this.filterPrincipal.model,

          order: order,
          orderby: orderby,

          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        setTimeout(() => {
          this.isBusy = false;
        }, 500);
        this.startPage = result.from;
        this.toPage = result.to;
        this.totalRows = result.total;
        this.paginate.currentPage = result.current_page;
        this.paginate.perPage = result.per_page;
        return result.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async deleteCreditor(params) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          await CreditorsService.DELETE_CREDITORS({
            id: params.id,
            user_id: this.currentUser.user_id,
          });
          this.$refs.refUserListTable.refresh();
          this.showToast(
            "success",
            "top-right",
            "Deleted!",
            "CheckIcon",
            "The creditor has been deleted."
          );
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    showKeywordModal(item) {
      this.paramsKeywords = {
        id: item.id,
        name: item.value,
      };
      this.creditorSelected = item;
      this.showModal = true;
    },
    showKeywordQty(item){
      return item.keywords_qty == null ? 0 : item.keywords_qty;
    }
  },
  watch: {
    G_UPDATE_TABLE_CREDITORS(newVal) {
      if (newVal) {
        if (this.$refs.refUserListTable === undefined) {
          this.$refs.refUserListTable.refresh();
        } else {
          this.$refs.refUserListTable.refresh();
        }
      }
    },
    sortBy(newValue, oldValue) {
      if (newValue) {
        this.$refs.refUserListTable.refresh();
      }
    },
  },
  async mounted() {
    await this.A_GET_STATUS_EEUU();
    await this.A_GET_COUNTRIES();
  },
};
</script>
<style lang="scss">
.b-table-top-row {
  position: sticky;
  top: 25px;
}

.widthRecovery {
  width: 100px;
  border-top: none;
}

.widthClients {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  padding-top: 5px;
}

.position {
  position: sticky;
  left: 200px;
}

.bg-red {
  background: rgb(190, 96, 96);
}
.custom-icon {
  filter: brightness(0%) invert(1);
}
</style>
