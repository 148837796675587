import { title } from "@core/utils/filter";

const CreditorsFields = [
  {
    key: "value",
    label: "Creditor Name",
    formatter: title,
    sortable: true,
  },
  {
    key: "c_assigned",
    label: "Clients",
    sortable: false,
  },
  {
    key: "name_parent",
    label: "Creditor Parent",
    sortable: false,
  },
  {
    key: "settlement",
    label: "sett avg",
    sortable: true,
  },
  {
    key: "settle",
    label: "Negotiated w/AMG",
    sortable: true,
  },
  // {
  //   key: "recovery",
  //   label: "RECOVERY",
  //   thClass: "text-center",
  // },
  {
    key: "customer",
    thClass: "text-center",
  },
  {
    key: "user_creator",
    label: "Info",
    thClass: "text-center",
  },
  {
    key: "keywords",
    label: "Keywords",
    thClass: "text-center",
    tdClass: "text-center"
  },
  {
    key: "action",
    thClass: "text-center",
    tdClass: "text-center",
  },
];

export default {
  CreditorsFields,
};
